<template>
  <div class="container-body">
    <div class="shadow p-3 mb-5 bg-body-tertiary rounded" style="width: 90%" v-if="vacante.type === 'Becario'">
      <div class="accordion accordion-flush" id="accordionPanelsStayOpenExample">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
              Datos De La Vacante
            </button>
          </h2>
          <div id="flush-collapseOne" class="accordion-collapse collapse">
            <div class="accordion-body">
              <div class="row flex center">
                <div class="col-md-8">
                  <div class="form-floating">
                    <input type="text" class="form-control" id="floatingInput" min="5" max="255"
                      v-model="vacante.titulo" placeholder="Nombre" :required="true" autocomplete="off" />
                    <label for="floatingInput">Nombre</label>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-floating">
                    <select class="form-select" id="floatingSelect" v-on:change="listMuni(vacante.estado)"
                      v-model="vacante.estado" required>
                      <option value="" selected disabled>Selecciona un estado</option>
                      <option v-for="(item, i) in estado" :key="i + 1" :value="item.estado">
                        {{ item.estado }}
                      </option>
                    </select>
                    <label for="floatingSelect">Estado</label>
                  </div>

                </div>
                <div class="col-md-6">
                  <div class="form-floating mt-2">
                    <input type="text" class="form-control" id="floatingInput" min="5" max="255"
                      v-model="vacante.ubicacion" placeholder="Nombre" required autocomplete="off" />
                    <label for="floatingInput">Ubicación</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating mt-2">
                    <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                      v-model="vacante.modalidad" required>
                      <option value="" selected disabled>Selecciona una opción</option>
                      <option value="Presencial">Presencial</option>
                      <option value="Híbrido">Híbrido</option>
                      <option value="Home Office Permanente">Home Office Permanente</option>
                      <!-- <option value="home office permanente">Home Office Temporal (COVID-19)</option> -->
                    </select>
                    <label for="floatingSelect">Modalidad</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating mt-2">
                    <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                      v-model="vacante.educacionMinima" required>
                      <option value="" selected disabled>Selecciona una opción</option>
                      <option value="Bachillerato">Estudiante de Bachillerato</option>
                      <option value="Licenciatura">Estudiante de Licenciatura</option>
                    </select>
                    <label for="floatingSelect">Educación Mínima Requerida</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating mt-2">
                    <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                      v-model="vacante.semestreMinimo" required>
                      <option value="" selected disabled>Selecciona una opción</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </select>
                    <label for="floatingSelect">Semestre Mínimo Requerido</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating mt-2">
                    <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                      v-model="vacante.idiomaMinimo" required>
                      <option value="" selected disabled>Selecciona una opción</option>
                      <option value="Ninguno">Ninguno</option>
                      <option value="Inglés">Inglés</option>
                      <option value="Chino Mandarin">Chino mandarín</option>
                      <option value="Alemán">Alemán</option>
                      <option value="Francés">Francés</option>
                      <option value="Portugués">Portugués</option>
                      <option value="Japonés">Japonés</option>
                      <option value="Árabe">Árabe</option>
                    </select>
                    <label for="floatingSelect">Idioma Mínimo Requerido</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating mt-2">
                    <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                      v-model="vacante.nivelMinimo" required>
                      <option value="" selected disabled>Selecciona una opción</option>
                      <option value="Ninguno">Ninguno</option>
                      <option value="Basico">Básico</option>
                      <option value="Intermedio">Intermedio</option>
                      <option value="Avanzado">Avanzado</option>
                    </select>
                    <label for="floatingSelect">Nivel Mínimo de Idioma</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating mt-2">
                    <select class="form-select" id="floatingSelect" v-model="vacante.workDayInitial">
                      <option value="" selected disabled>Seleccione una opción</option>
                      <option v-for="(item, i) in labourDay" :key="i + 1" :value="item.day">{{ item.day }}</option>
                    </select>
                    <label for="floatingSelect">Día Inicial de Semana de Prácticas</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating mt-2">
                    <select class="form-select" id="floatingSelect" v-model="vacante.workDayFinal">
                      <option value="" selected disabled>Seleccione una opción</option>
                      <option v-for="(item, i) in labourDay" :key="i + 1" :value="item.day">{{ item.day }}</option>
                    </select>
                    <label for="floatingSelect">Día Final de Semana de Prácticas</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating mt-2">
                    <select type="text" class="form-control" id="floatingInput1" v-model="vacante.horarioDe"
                      placeholder="Seleccione una hora" @click="toggleHourList('horaDe')" :required="true"
                      autocomplete="off" readonly>
                      <option value="" selected disabled>Seleccione una opción</option>
                      <option v-for="hour in hours" :key="hour" @click="selectHour(hour, 'horaDe')">{{ hour }}</option>
                    </select>
                    <label for="floatingInput1">Horario de Entrada (24 hrs.)</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating mt-2">
                    <select type="text" class="form-control" id="floatingInput2" v-model="vacante.horarioA"
                      placeholder="Seleccione una hora" @click="toggleHourList('horaA')" :required="true"
                      autocomplete="off" readonly>
                      <option value="" selected disabled>Seleccione una opción</option>
                      <option v-for="hour in hours" :key="hour" @click="selectHour(hour, 'horaA')">{{ hour }}</option>
                    </select>
                    <label for="floatingInput2">Horario de Salida (24 hrs)</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false"
              aria-controls="panelsStayOpen-collapseTwo">
              Información De La Vacante
            </button>
          </h2>
          <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse">
            <div class="accordion-body">
              <div class="col-md-12 mb-5">
                <QuillEditor id="editor" theme="snow" placeholder="Información de la vacante"
                  v-model:content="vacante.descripcion" contentType="html"
                  :toolbar="['bold', 'italic', 'underline', { list: 'ordered' }, { list: 'bullet' }, { align: [] }]" />
              </div>
              <p style="font-weight: bold">Habilidades, certificaciones y/o sistemas requeridos</p>
              <div class="col-md-12 mb-5">
                <QuillEditor id="editor" theme="snow" placeholder="Habilidades, certificaciones y/o sistemas requeridos"
                  v-model:content="vacante.habilidades" contentType="html"
                  :toolbar="['bold', 'italic', 'underline', { list: 'ordered' }, { list: 'bullet' }, { align: [] }]" />
              </div>
              <p style="font-weight: bold">Beca Mensual</p>
              <div class="col-md-6">
                <div class="form-floating">
                  <input type="text" class="form-control" id="floatingInput" @keypress="onlyNumber"
                    v-model="vacante.becaDe" @blur="formatBecaDe" placeholder="0.00" required autocomplete="off" />
                  <label for="floatingInput">$</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input type="text" class="form-control" id="floatingInput" @keypress="onlyNumber"
                    v-model="vacante.becaA" @blur="formatBecaA" placeholder="0.00" required autocomplete="off" />
                  <label for="floatingInput">$</label>
                </div>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="flexCheckDefault" v-model="vacante.privateSalary" />
                <label class="form-check-label" for="flexCheckDefault"> ¿Deseas que el sueldo no sea mostrado? </label>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false"
              aria-controls="panelsStayOpen-collapseThree">
              Preguntas De Preselección
            </button>
          </h2>
          <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse">
            <div class="accordion-body">
              <div class="preguntas-preseleccion">
                <label style="color: #1b4f99; font-weight: bold; font-size: 15px"></label>
                <div class="header-preguntas">
                  <p>Puedes añadir preguntas de preseleción.</p>
                </div>
                <div class="card card-body mb-2" style="width: 100%" v-for="(questions, i) in vacante.questions" :key="i">
                  <div>
                    <div class="form-floating mb-3 mt-3">
                      <input type="text" class="form-control" id="floatingInput" placeholder="Escribe una pregunta"
                        v-model="questions.description" autocomplete="off" v-sensibilidad required />
                      <label for="floatingInput">Escribe una pregunta</label>
                    </div>
                    <div class="col-12" style="display: flex; flex-direction: row">
                      <div class="respuesta col-4">
                        <div class="form-floating">
                          <select class="form-select" id="tipo-respuesta" aria-label="Floating label select example"
                            v-model="questions.type" required>
                            <option value="" selected disabled>Selecciona una respuesta</option>
                            <option value="num" id="num">Numérico</option>
                            <option value="options" id="options">Si/No</option>
                          </select>
                          <label for="tipo-respuesta">Tipo de Respuesta</label>
                        </div>
                      </div>
                      &nbsp; &nbsp;
                      <div class="respuesta col-8" style="display: flex" v-if="questions.type === 'options'">
                        <div class="col">
                          <div class="form-floating">
                            <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                              v-model="questions.ideal_answer" required>
                              <option value="" selected disabled>Respuesta ideal</option>
                              <option value="yes">Sí</option>
                              <option value="no">No</option>
                            </select>
                            <label for="floatingSelect">Selecciona la respuesta ideal</label>
                          </div>
                        </div>
                        &nbsp; &nbsp;
                        <div class="col" style="display: flex; justify-content: center; align-items: center">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="questions.essential"
                              id="flexCheckDefault" required />
                            <label class="form-check-label" for="flexCheckDefault"> Pregunta de descarte </label>
                          </div>
                        </div>
                      </div>
                      <div class="respuesta col-8" style="display: flex" v-else-if="questions.type === 'num'">
                        <div class="col">
                          <div class="form-floating">
                            <input type="number" class="form-control" id="floatingInput" autocomplete="off"
                              placeholder="Escribe la respuesta ideal" v-model="questions.ideal_answer" required />
                            <label for="floatingInput">Escribe la respuesta ideal</label>
                          </div>
                        </div>
                        &nbsp; &nbsp;
                        <div class="col" style="display: flex; justify-content: center; align-items: center">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="questions.essential"
                              id="flexCheckDefault" required />
                            <label class="form-check-label" for="flexCheckDefault"> Pregunta de descarte </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="margin-top: 2%; width: 100%; display: flex; justify-content: center; align-items: center">
                    <box-icon name="trash" style="cursor: pointer" @click="deleteQuestion(i)"></box-icon>
                  </div>
                </div>
                <div class="col-12 J-flex center mt-3">
                  <div class="col-6 J-flex center">
                    <button class="J-btn J-btn-secondary" type="button"
                      @click="agregateQuestion(vacante.questions)">Agregar pregunta</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false"
              aria-controls="panelsStayOpen-collapseFour">
              Dashboard
            </button>
          </h2>
          <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse">
            <div class="accordion-body">
              <div class="row center">
                <div class="row mb-2">
                  <div class="col-md-6">
                    <label for="supervisor" class="form-label">
                      <h6>Unidad / División</h6>
                    </label>
                    <input list="clients" class="form-control" v-model="vacante.id_client" :disabled="details"
                      required />
                    <datalist id="clients">
                      <option value="" selected disabled>Seleccione...</option>
                      <option v-for="state in clients" v-bind:key="state" :value="state.informationClient.nameCompany">
                        {{ state.informationClient.nameCompany }}
                      </option>
                    </datalist>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end mt-2">
        <button class="btn btn-danger me-md-2" type="button" v-on:click="editInf()">Cancelar</button>
        <button class="btn btn-success" type="button" v-on:click="updateVacanteBecario()">Guardar</button>
      </div>
    </div>
    <div class="shadow p-3 mb-5 bg-body-tertiary rounded" style="width: 90%" v-if="vacante.type === 'Egresado'">
      <div class="accordion accordion-flush" id="accordionPanelsStayOpenExample">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
              Datos De La Vacante
            </button>
          </h2>
          <div id="flush-collapseOne" class="accordion-collapse collapse">
            <div class="accordion-body">
              <div class="row flex center">
                <div class="col-md-8">
                  <div class="form-floating">
                    <input type="text" class="form-control" id="floatingInput" min="5" max="255"
                      v-model="vacante.titulo" placeholder="Nombre" :required="true" autocomplete="off" />
                    <label for="floatingInput">Nombre</label>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-floating">
                    <select class="form-select" id="floatingSelect" v-on:change="listMuni(vacante.estado)"
                      v-model="vacante.estado" required>
                      <option value="" selected disabled>Selecciona un estado</option>
                      <option v-for="(item, i) in estado" :key="i + 1" :value="item.estado">
                        {{ item.estado }}
                      </option>
                    </select>
                    <label for="floatingSelect">Estado</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating mt-2">
                    <input type="text" class="form-control" id="floatingInput" min="5" max="255"
                      v-model="vacante.ubicacion" placeholder="Nombre" required autocomplete="off" />
                    <label for="floatingInput">Ubicación</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating mt-2">
                    <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                      v-model="vacante.contratacion" required>
                      <option value="" selected disabled>Selecciona una opción</option>
                      <option value="Permanente">Permanente</option>
                      <option value="Temporal">Temporal</option>
                      <option value="Por Proyecto">Por Proyecto</option>
                    </select>
                    <label for="floatingSelect">Contratación</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating mt-2">
                    <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                      v-model="vacante.modalidad" required>
                      <option value="" selected disabled>Selecciona una opción</option>
                      <option value="Presencial">Presencial</option>
                      <option value="Híbrido">Híbrido</option>
                      <option value="Home Office Permanente">Home Office Permanente</option>
                      <!-- <option value="home office permanente">Home Office Temporal (COVID-19)</option> -->
                    </select>
                    <label for="floatingSelect">Modalidad</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating mt-2">
                    <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                      v-model="vacante.educacionMinima" required>
                      <option value="" selected disabled>Selecciona una opción</option>
                      <option value="Licenciatura">Licenciatura</option>
                      <option value="Maestria">Maestria</option>
                      <option value="Doctorado">Doctorado</option>
                    </select>
                    <label for="floatingSelect">Educación Mínima Requerida</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating mt-2">
                    <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                      v-model="vacante.idiomaMinimo" required>
                      <option value="" selected disabled>Selecciona una opción</option>
                      <option value="Ninguno">Ninguno</option>
                      <option value="Inglés">Inglés</option>
                      <option value="Chino Mandarin">Chino mandarín</option>
                      <option value="Alemán">Alemán</option>
                      <option value="Francés">Francés</option>
                      <option value="Portugués">Portugués</option>
                      <option value="Japonés">Japonés</option>
                      <option value="Árabe">Árabe</option>
                    </select>
                    <label for="floatingSelect">Idioma Mínimo Requerido</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating mt-2">
                    <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                      v-model="vacante.nivelMinimo" required>
                      <option value="" selected disabled>Selecciona una opción</option>
                      <option value="Ninguno">Ninguno</option>
                      <option value="Basico">Básico</option>
                      <option value="Intermedio">Intermedio</option>
                      <option value="Avanzado">Avanzado</option>
                    </select>
                    <label for="floatingSelect">Nivel Mínimo de Idioma</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating mt-2">
                    <select class="form-select" id="floatingSelect" v-model="vacante.workDayInitial">
                      <option value="" selected disabled>Seleccione una opción</option>
                      <option v-for="(item, i) in labourDay" :key="i + 1" :value="item.day">{{ item.day }}</option>
                    </select>
                    <label for="floatingSelect">Día Inicial de Jornada Laboral</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating mt-2">
                    <select class="form-select" id="floatingSelect" v-model="vacante.workDayFinal">
                      <option value="" selected disabled>Seleccione una opción</option>
                      <option v-for="(item, i) in labourDay" :key="i + 1" :value="item.day">{{ item.day }}</option>
                    </select>
                    <label for="floatingSelect">Día Final de Jornada Laboral</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating mt-2">
                    <select type="text" class="form-control" id="floatingInput1" v-model="vacante.horarioDe"
                      placeholder="Seleccione una hora" @click="toggleHourList('horaDe')" :required="true"
                      autocomplete="off" readonly>
                      <option value="" selected disabled>Seleccione una opción</option>
                      <option v-for="hour in hours" :key="hour" @click="selectHour(hour, 'horaDe')">{{ hour }}</option>
                    </select>
                    <label for="floatingInput1">Horario de Entrada (24 hrs.)</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating mt-2">
                    <select type="text" class="form-control" id="floatingInput2" v-model="vacante.horarioA"
                      placeholder="Seleccione una hora" @click="toggleHourList('horaA')" :required="true"
                      autocomplete="off" readonly>
                      <option value="" selected disabled>Seleccione una opción</option>
                      <option v-for="hour in hours" :key="hour" @click="selectHour(hour, 'horaA')">{{ hour }}</option>
                    </select>
                    <label for="floatingInput2">Horario de Salida (24 hrs)</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false"
              aria-controls="panelsStayOpen-collapseTwo">
              Información De La Vacante
            </button>
          </h2>
          <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse">
            <div class="accordion-body">
              <div class="col-md-12 mb-5">
                <QuillEditor id="editor" theme="snow" placeholder="Información de la vacante"
                  v-model:content="vacante.descripcion" contentType="html"
                  :toolbar="['bold', 'italic', 'underline', { list: 'ordered' }, { list: 'bullet' }, { align: [] }]" />
              </div>
              <p style="font-weight: bold">Habilidades, certificaciones y/o sistemas requeridos</p>
              <div class="col-md-12 mb-5">
                <QuillEditor id="editor" theme="snow" placeholder="Habilidades, certificaciones y/o sistemas requeridos"
                  v-model:content="vacante.habilidades" contentType="html"
                  :toolbar="['bold', 'italic', 'underline', { list: 'ordered' }, { list: 'bullet' }, { align: [] }]" />
              </div>
              <p style="font-weight: bold">Sueldo Mensual</p>
              <div class="col-md-6">
                <div class="form-floating">
                  <input type="text" class="form-control" id="floatingInput" @keypress="onlyNumber"
                    v-model="vacante.becaDe" @blur="formatBecaDe" placeholder="0.00" required autocomplete="off" />
                  <label for="floatingInput">$</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input type="text" class="form-control" id="floatingInput" @keypress="onlyNumber"
                    v-model="vacante.becaA" @blur="formatBecaA" placeholder="0.00" required autocomplete="off" />
                  <label for="floatingInput">$</label>
                </div>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="flexCheckDefault" v-model="vacante.privateSalary" />
                <label class="form-check-label" for="flexCheckDefault"> ¿Deseas que el sueldo no sea mostrado? </label>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false"
              aria-controls="panelsStayOpen-collapseThree">
              Preguntas De Preselección
            </button>
          </h2>
          <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse">
            <div class="accordion-body">
              <div class="preguntas-preseleccion">
                <label style="color: #1b4f99; font-weight: bold; font-size: 15px"></label>
                <div class="header-preguntas">
                  <p>Puedes añadir preguntas de preseleción.</p>
                </div>
                <div class="card card-body mb-3" style="width: 100%" v-for="(questions, i) in vacante.questions" :key="i">
                  <div>
                    <div class="form-floating mb-3 mt-3">
                      <input type="text" class="form-control" id="floatingInput" placeholder="Escribe una pregunta"
                        v-model="questions.description" autocomplete="off" v-sensibilidad required />
                      <label for="floatingInput">Escribe una pregunta</label>
                    </div>
                    <div class="col-12" style="display: flex; flex-direction: row">
                      <div class="respuesta col-4">
                        <div class="form-floating">
                          <select class="form-select" id="tipo-respuesta" aria-label="Floating label select example"
                            v-model="questions.type" required>
                            <option value="" selected disabled>Selecciona una respuesta</option>
                            <option value="num" id="num">Numérico</option>
                            <option value="options" id="options">Si/No</option>
                          </select>
                          <label for="tipo-respuesta">Tipo de Respuesta</label>
                        </div>
                      </div>
                      &nbsp; &nbsp;
                      <div class="respuesta col-8" style="display: flex" v-if="questions.type === 'options'">
                        <div class="col">
                          <div class="form-floating">
                            <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                              v-model="questions.ideal_answer" required>
                              <option value="" selected disabled>Respuesta ideal</option>
                              <option value="yes">Sí</option>
                              <option value="no">No</option>
                            </select>
                            <label for="floatingSelect">Selecciona la respuesta ideal</label>
                          </div>
                        </div>
                        &nbsp; &nbsp;
                        <div class="col" style="display: flex; justify-content: center; align-items: center">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="questions.essential"
                              id="flexCheckDefault" required />
                            <label class="form-check-label" for="flexCheckDefault"> Pregunta de descarte </label>
                          </div>
                        </div>
                      </div>
                      <div class="respuesta col-8" style="display: flex" v-else-if="questions.type === 'num'">
                        <div class="col">
                          <div class="form-floating">
                            <input type="number" class="form-control" id="floatingInput" autocomplete="off"
                              placeholder="Escribe la respuesta ideal" v-model="questions.ideal_answer" required />
                            <label for="floatingInput">Escribe la respuesta ideal</label>
                          </div>
                        </div>
                        &nbsp; &nbsp;
                        <div class="col" style="display: flex; justify-content: center; align-items: center">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="questions.essential"
                              id="flexCheckDefault" required />
                            <label class="form-check-label" for="flexCheckDefault"> Pregunta de descarte </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="margin-top: 2%; width: 100%; display: flex; justify-content: center; align-items: center">
                    <box-icon name="trash" style="cursor: pointer" @click="deleteQuestion(i)"></box-icon>
                  </div>
                </div>
                <div class="col-12 J-flex center mt-3">
                  <div class="col-6 J-flex center">
                    <button class="J-btn J-btn-secondary" type="button"
                      @click="agregateQuestion(vacante.questions)">Agregar pregunta</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse">
          <div class="accordion-body">
            <div class="row center">
              <div class="row mb-2">
                <div class="col-md-6">
                  <label for="supervisor" class="form-label">
                    <h6>Unidad / División</h6>
                  </label>
                  <input list="clients" class="form-control" v-model="vacante.id_client" :disabled="details" required />
                  <datalist id="clients">
                    <option value="" selected disabled>Seleccione...</option>
                    <option v-for="state in clients" v-bind:key="state" :value="state.informationClient.nameCompany">
                      {{ state.informationClient.nameCompany }}
                    </option>
                  </datalist>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end mt-2">
        <button class="btn btn-danger me-md-2" type="button" v-on:click="editInf()">Cancelar</button>
        <button class="btn btn-success" type="button" v-on:click="updateVacanteBecario()">Guardar</button>
      </div>
    </div>
  </div>
</template>
<script>
import { CompanyService } from "@/services";
import { UserService } from "@/services";
import * as ClientService from "../services/client.js";

export default {
  created() {
    const id_from_url = this.$route.params.id;
    this.vacantesById(id_from_url);
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem("user") || null);
    const { company: { _id: _id_company } } = user;
    this.getClientCompany(_id_company);
    this.listEstado();
  },
  data: () => ({
    vacante: {
      titulo: "",
      estado: "",
      contratacion: "",
      ubicacion: "",
      modalidad: "",
      educacionMinima: "",
      semestreMinimo: "",
      idiomaMinimo: "",
      nivelMinimo: "",
      workDayInitial: "",
      workDayFinal: "",
      becaDe: "0",
      becaA: "0",
      privateSalary: false,
      horarioDe: "",
      horarioA: "",
      descripcion: "",
      habilidades: "",
      type: "",
      questions: [],
      id_client: "",
    },
    dashboard: {
      _id_vacante: "",
      vacante: "",
      federalEntities: ""
    },
    dataQuestions: [
      {
        description: "",
        type: "",
        ideal_answer: "",
        essential: false,
      },
    ],
    estado: [],
    clients: [],
    enableInput: true,
    enableInput1: true,
    labourDay: [
      {
        day: "Lunes",
      },
      {
        day: "Martes",
      },
      {
        day: "Miércoles",
      },
      {
        day: "Jueves",
      },
      {
        day: "Viernes",
      },
      {
        day: "Sábado",
      },
      {
        day: "Domingo",
      },
    ],
    selectedHour: "",
    showHourListDe: false,
    showHourListA: false,
    hours: [
      "00:00",
      "00:30",
      "01:00",
      "01:30",
      "02:00",
      "02:30",
      "03:00",
      "03:30",
      "04:00",
      "04:30",
      "05:00",
      "05:30",
      "06:00",
      "06:30",
      "07:00",
      "07:30",
      "08:00",
      "08:30",
      "09:00",
      "09:30",
      "10:00",
      "10:30",
      "11:00",
      "11:30",
      "12:00",
      "12:30",
      "13:00",
      "13:30",
      "14:00",
      "14:30",
      "15:00",
      "15:30",
      "16:00",
      "16:30",
      "17:00",
      "17:30",
      "18:00",
      "18:30",
      "19:00",
      "19:30",
      "20:00",
      "20:30",
      "21:00",
      "21:30",
      "22:00",
      "22:30",
      "23:00",
      "23:30",
    ],
  }),
  methods: {
    //Method para traer la vacante
    async vacantesById(id_vacante) {
      try {
        await CompanyService.vacantesById(id_vacante)
          .then((resp) => {
            let { data } = resp;
            this.vacante = data.data;
            console.log('this.vacante', this.vacante);
          })
          .catch((e) => {
            this.$swal({
              position: "top-end",
              toast: true,
              icon: "error",
              title: e.response.data.message,
              showConfirmButton: false,
              timer: 5000,
            });
          });
      } catch (error) {
        console.log(error);
      }
    },
    async getClientCompany(filter) {
      let resp = await ClientService.companyClientListActivo({ _id_company: filter });
      this.clients = resp.data.data;
    },
    agregateQuestion(array) {
      array.push({
        description: "",
        type: "",
        ideal_answer: "",
        essential: false,
      });
    },
    // deleteQuestion(index) {
    //   this.vacante.questions.splice(index, 1);
    // },
    deleteQuestion(index) {
      this.$swal({
        title: "¿Desea eliminar esta pregunta?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonColor: "#4ca746",
        cancelButtonText: "Cancelar",
        confirmButtonText: "¡Sí, eliminar pregunta!",
      }).then((result) => {
        if (result.isConfirmed) {
          // Eliminar la pregunta del array vacante.questions
          this.vacante.questions.splice(index, 1);

          // Muestra una alerta de éxito
          this.$swal({
            title: "¡Pregunta Eliminada!",
            text: "La pregunta ha sido eliminada correctamente.",
            icon: "success",
          });
        }
      });
    },

    editInf() {
      window.location.href = "/vacantes/empresa";
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 44) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    updateVacanteBecario() {
      CompanyService.updateInfoVacanteBecario(this.vacante)
        .then((response) => {
          const { _id, titulo, estado } = this.vacante;
          this.dashboard._id_vacante = _id;
          this.dashboard.vacante = titulo;
          this.dashboard.federalEntities = estado;

          CompanyService.updateDashboardByVacante(this.dashboard);

          window.location.href = "/vacantes/empresa";
          this.$swal({
            position: "top-center",
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 2500,
          });
        })
        .catch((e) => {
          this.$swal({
            position: "top-center",
            icon: "error",
            title: e.message,
            showConfirmButton: false,
            timer: 2500,
          });
        });
    },
    formatBecaDe() {
      const becaDeValue = this.vacante.becaDe.trim();
      if (becaDeValue !== "") {
        const parsedValue = parseInt(becaDeValue);
        if (!isNaN(parsedValue)) {
          this.vacante.becaDe = parsedValue.toLocaleString("es-MX", {
            style: "currency",
            currency: "MXN",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
        }
      }
    },
    formatBecaA() {
      const becaAValue = this.vacante.becaA.trim();
      if (becaAValue !== "") {
        const parsedValue = parseFloat(becaAValue);
        if (!isNaN(parsedValue)) {
          this.vacante.becaA = parsedValue.toLocaleString("es-MX", {
            style: "currency",
            currency: "MXN",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
        }
      }
    },
    toggleHourList(input) {
      if (input === "horaDe") {
        this.showHourListDe = !this.showHourListDe;
        this.showHourListA = false;
      } else {
        this.showHourListA = !this.showHourListA;
        this.showHourListDe = false;
      }
    },
    selectHour(hour, input) {
      if (input === "horaDe") {
        this.formData.horarioDe = hour;
        this.showHourListDe = false;
      } else {
        this.formData.horarioA = hour;
        this.showHourListA = false;
      }
    },
    async listMuni(estado) {
      const parametroCodificado = encodeURIComponent(estado);
      try {
        let resp = await UserService.getEstado({ estado: parametroCodificado });
        let { data } = resp.data;
        this.municipio = data;
      } catch ($e) {
        console.log($e.message);
      }
    },
    async listEstado() {
      try {
        let resp = await UserService.getEstado();
        let { data } = resp.data;
        this.estado = data;
        console.log('this.estado', this.estado);
      } catch ($e) {
        console.log($e.message);
      }
    },
  },
};
</script>
<style land="scss" scoped>
@import "../styles/UpdateVacante.scss";
</style>
